import React from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import AniLink from "gatsby-plugin-transition-link/AniLink";

export default function Tasty() {

  const data = useStaticQuery(graphql`{
  tasty: file(relativePath: {eq: "tasty.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
}
`)


  return (
    <Layout>
      <SEO title="Referencie" />
      <GatsbyImage
        image={data.tasty.childImageSharp.gatsbyImageData}
        objectFit="cover"
        objectPosition="0% 0%"
        alt="Mobilná aplikácia" />  
      <div className="section-pd section-pd-bottom white-bg">
        <div className="container">
          <div className="row">
          <div className="col-12">
              <h1>Mobilná aplikácia pre objednávanie chutných burgrov.</h1>
              <p>Tento chutný projekt sme robili pre Tasty Burgre. Aplikácia slúži na objednávku jedál z tejto reštaurácie. Objednávka ide priamo do POS systému reštaurácií, ktoré sú vyvinuté nami.</p>
            </div>
            
          </div>
          
        </div>
      </div>
     
         
          

      
      



      <div className="ref-buttons">
        <AniLink className="back-to-ref" cover direction="left" to="/referencie" bg="#000" duration={1}>Späť na referencie</AniLink>
        <AniLink className="next-project" cover direction="left" to="/referencie/roomassistant" bg="#000" duration={1}>Ďalší projekt</AniLink>
        
      </div>

    </Layout>
  );

}
